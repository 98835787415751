import store from '../redux/store'

const DELETE = request('DELETE')
const GET = request('GET')
const POST = request('POST')
const PUT = request('PUT')
const PATCH = request('PATCH')

function request(method: string) {
  return async (url: string, payload?: any) => {
    const requestOptions: any = {
      method,
      headers: authHeader(url)
    }
    if (payload) {
      if (payload.contentType) {
        requestOptions.headers['Content-Type'] = payload.contentType
      }
      if (payload.body) {
        requestOptions.body = payload.body
      }
    }

    if (payload?.filename) {
      return fetch(url, requestOptions).then((response) =>
        handleFileFetchResponse(response, payload.filename)
      )
    } else {
      return fetch(url, requestOptions).then(handleFetchResponse)
    }
  }

  // Private helper methods
  function authHeader(url: string) {
    const token = store.getState().userDetails.token
    if (url && token) {
      return { Authorization: `Bearer ${token}` }
    } else {
      return {}
    }
  }

  async function handleFetchResponse(response: any) {
    if (response.ok) {
      return response.json().catch(() => null) // in some cases we don't receive a response, only a status code. (empty response body).json() => error, which is caught here
    } else {
      throw await response.json()
    }
  }

  async function handleFileFetchResponse(response: any, filename: string) {
    // To read the byte array in full, we make use of the blob() function of the response
    response.blob().then((blob: Blob) => {
      // As the browsers currently don't support a standard way of downloading files from a request,
      // we have to create an object URL for the incoming Blob and force the browser to download the image with a hidden <a> HTML element.
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
      return true
    })
  }
}

const FetchWrapper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
}

export default FetchWrapper
