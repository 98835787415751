import { Actions } from '../actions/action'

const initialState = {
  orderId: '',
  labelId: ''
}

export default function orderViewReducer(state = initialState, action: any) {
  switch (action.type) {
    case Actions.ORDER_LINK.SET_ORDER_ID_FILTER: {
      return { ...state, orderId: action.payload }
    }
    case Actions.ORDER_LINK.SET_LABEL_ID_FILTER: {
      return { ...state, labelId: action.payload }
    }
    case Actions.ORDER_LINK.CLEAR_ORDER_ID_FILTER: {
      return { ...state, orderId: '' }
    }
    case Actions.ORDER_LINK.CLEAR_LABEL_ID_FILTER: {
      return { ...state, labelId: '' }
    }
    default:
      return state
  }
}
