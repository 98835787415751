import Keycloak from 'keycloak-js'
import { User } from '../models/User'

const kc = new Keycloak(process.env.REACT_APP_KEYCLOAK_CONFIG_FILE)

const initKeycloak = async (onAuthenticatedCallback: () => void) => {
  await kc
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      checkLoginIframe: false
    })
    .then((authenticated) => {
      if (authenticated) {
        if (hasLoginRole()) {
          onAuthenticatedCallback()
        } else {
          // logout (redirect the user) when the user does not have the bb20-login role
          doLogout()
        }
      } else {
        doLogin()
      }
    })
    .catch(console.error)
}

const doLogin = kc.login
const doLogout = kc.logout
const getUsername = () => kc.tokenParsed?.preferred_username
const getUser = (): User => ({
  username: getUsername(),
  roles: getUserRoles(),
  token: getToken(),
  authenticated: isLoggedIn()
})
const hasAdminRole = () => getUserRoles()?.includes(RIGHTS.ADMIN_RIGHT)
const hasLaneImportRole = () =>
  getUserRoles()?.includes(RIGHTS.BULK_LANE_IMPORT_RIGHT)
const hasLaneExportRole = () =>
  getUserRoles()?.includes(RIGHTS.BULK_LANE_EXPORT_RIGHT)
const hasLoginRole = () => getUserRoles()?.includes(RIGHTS.LOGIN_RIGHT)
const hasPrintLabelRole = () =>
  getUserRoles()?.includes(RIGHTS.PRINT_LABELS_RIGHT)
const hasReOpenBulkRole = () =>
  getUserRoles()?.includes(RIGHTS.REOPEN_BULK_RIGHT)
const hasAllWarehousesRight = () =>
  getUserRoles()?.includes(RIGHTS.ALL_WAREHOUSES)
const hasFrontendRight = () => getUserRoles()?.includes(RIGHTS.FRONTEND)

const labelHeaderText = () => kc.tokenParsed?.label_header_text

const getToken = () => kc.token
const getUserRoles = () => kc?.tokenParsed?.realm_access?.roles
const isLoggedIn = () => !!kc.token
const updateToken = (
  successCallback:
    | ((value: boolean) => boolean | PromiseLike<boolean>)
    | null
    | undefined
) => kc.updateToken(5).then(successCallback).catch(doLogin)

enum RIGHTS {
  LOGIN_RIGHT = 'bb20_login_right',
  BULK_LANE_EXPORT_RIGHT = 'bb20_bulk_lane_export_right',
  BULK_LANE_IMPORT_RIGHT = 'bb20_bulk_lane_import_right',
  REOPEN_BULK_RIGHT = 'bb20_reopen_bulk_right',
  PRINT_LABELS_RIGHT = 'bb20_print_labels_right',
  ALL_WAREHOUSES = 'bb20_all_warehouses_right',
  FRONTEND = 'bb20_frontend_right',
  ADMIN_RIGHT = 'bb20_admin_right'
}

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUser,
  hasAdminRole,
  hasLaneImportRole,
  hasLaneExportRole,
  hasLoginRole,
  hasPrintLabelRole,
  labelHeaderText,
  hasReOpenBulkRole,
  hasAllWarehousesRight,
  hasFrontendRight
}

export default AuthService
