import { Actions } from '../actions/action'
import { PayloadAction } from '@reduxjs/toolkit'

export interface TimezoneState {
  timezone: string
}

const initialState: TimezoneState = {
  timezone: ''
}

export default function timeReducer(
  state: TimezoneState = initialState,
  action: PayloadAction<string>
): TimezoneState {
  switch (action.type) {
    case Actions.TIME_ZONE.SET_TIME_ZONE: {
      return { ...state, timezone: action.payload }
    }
    default:
      return state
  }
}
